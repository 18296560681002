import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import GoogleMapReact from 'google-map-react';
import Maps from "../../component/Maps";
import property1 from "../../asset/images/property/big-img-01.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowDown, FaSearch } from "react-icons/fa";
import {
  faBathtub,
  faBed,
  faCalendar,
  faLocation,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { Slider } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import Detailheader from "../../component/Detailheader";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../Custom.css";

// import required modules
import { Navigation } from "swiper";
import axios from "../../utlilites/axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";

const Propertydetails = () => {
const navigate = new useNavigate();
const [loader, setLoader] = useState(true);
const [path, setPath] = useState();
  const [detail, setDetails] = useState();
  const [desc, setdesc] = useState();
  const [contact, setContact] = useState(false);
  const [api, setApi] = useState();
  const [name, setName] = useState();
  const [addres, setAddres] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [price, setPrice] = useState();
  const [proprty, setProprty] = useState();
  const [message, setMessage] = useState();
  // const [details, setDetails] = useState();
  const [status, setStatus] = useState();
  const [cid, setCid] = useState();

  const { id } = useParams();
  const token = localStorage.getItem("accesstoken");
  function allproperty(){

    var config = {
      method: "get",
      url: `http://gtaadmin.sasstechnologies.com/api/detail/property/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  
    axios(config)
      .then(function (response) {
        setLoader(true);
        console.log(response, "propertydetails");
        setDetails(response.data.property);
        setdesc(response.data.property.description);
        setCid(response.data.property.id);
        setPath(response.data.imagepath)
        console.log(desc, "desc");
        if(response.status){
          setLoader(false);
        }
  
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(()=>{
    allproperty();
  },[])

  //protected routing
    useEffect(()=>{
      setLoader(true);
      console.log(token)
  if(token){
    
  }else{
    navigate('/userlogin')
  }
    },[]) 
 //protected routing
 
 const contactapi = () => {
  const contct = new FormData();
  contct.append("property_id", cid);
  contct.append("name", name);
  contct.append("address", addres);
  contct.append("phone_number", number);
  contct.append("email", email);
  contct.append("price", price);
  console.log(price,"..................")
  contct.append("property_type", proprty);
  contct.append("message", message);
  // const token = localStorage.getItem("accesstoken");f
  var config = {
    method: "post",
    url: `http://gtaadmin.sasstechnologies.com/api/contactus/property`,
    data: contct,
    headers: {
      Accept: "application/json",
      // Authorization: `Bearer ${token}`,
    },
  };

  axios(config)
    .then(function (response) {
      setLoader(true);
      setApi(response.data);
      setStatus(response.data.status);

      // setPath(response.data.imagepath);
      console.log(response, "checking");
      // console.log(response.data.imagepath, "path");
      if (response.status) {
        swal({
          title: "Good job!",
          text: response.data.message,
          text: "your message send successfuly",
          icon: "success",
          button: "Ok",
        });
        setShow(false)
      } else {
      }
      if (response.status) {
        setLoader(false);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => {
  // console.log("lund",e)
  // setCid();
  // allproperty(e);
  setShow(true);
};

  return (
    <>
      <Detailheader />
      <br />
      {loader ? <div className="loader"></div> : null}
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="half-map-full">
                <div className="map-canvas">
                  <Maps />
                </div>
              </div>
            </div>
            <div className="col-lg-6 cardscroll">
              <div className="property">
                <div className="slider mt-1 ">
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {detail?.property_image.map((x) => (
                    <SwiperSlide>
                      <img src={`${path}${x.image}`} alt="" />
                    </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="card p-3 br-theme">
                  <div className="row">
                    <div className="col-lg-9">
                    <h5>
                    {detail ? detail?.property_type : "No Property Type Found"}
                    <span className="me-1 ms-5">
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </span>
                    {detail ? detail?.address : "No address Found"}
                  </h5>
                  <h4>
                    $
                    {detail?.floor.map((floor) => {
                      return <>{floor.rent}</>;
                    })}
                  </h4>
                  <h6>
                    <span className="me-1">
                      <FontAwesomeIcon icon={faBed} />
                    </span>
                    {detail?.floor.map((floor) => {
                      return <>{floor.bedroom}</>;
                    })}{" "}
                    Bed
                    <span className="me-1 ms-3">
                      <FontAwesomeIcon icon={faBathtub} />
                    </span>
                    {detail?.floor.map((floor) => {
                      return <>{floor.bathroom}</>;
                    })}
                    Bath{" "}
                    <span className="me-1 ms-3">
                      <FontAwesomeIcon icon={faLocation} />
                    </span>
                    {detail?.floor.map((floor) => {
                      return <>{floor.unit_size}</>;
                    })}
                    Sq.ft
                    <span className="me-1 ms-3">
                      <FontAwesomeIcon icon={faCalendar} />
                    </span>
                    Available :{" "}
                    {detail?.floor.map((floor) => {
                      return <>{floor.availability}</>;
                    })}
                  </h6>
                    </div>
                    <div className="col-lg-3">
                            <button
                              className="btn btn-success text-white btn-block w-100 round"
                              onClick={() => handleShow(detail?.id)}
                            >
                              Contact
                            </button>
                  </div>
               
                </div>
              </div>
              <div className="card  mt-3 br-theme">
                <h4 className="text-center text-theme pt-3">
                  Property Details
                </h4>

                <hr />
                <div className="row">
                  <div className="col-lg-12">
                    <h6 className="ms-1">Utilies Included</h6>
                    <ul className="d-flex p-0">
                      {detail?.utility_include.map((utility) => {
                        return (
                          <>
                            <li className="li">{utility.name}</li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-lg-12">
                    <h6 className="ms-1">Categories</h6>
                    <ul className="d-flex p-0">
                      {detail?.category.map((category) => {
                        return (
                          <>
                            <li className="li">{category.name}</li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="ms-1">Year</h6>
                    <ul className="d-flex p-0">
                      <li className="li">
                        {detail ? detail?.year_build : null}
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-6">
                    <h6 className="ms-1">Pet Firendly</h6>
                    <ul className="d-flex p-0">
                      <li className="li">
                        {detail ? detail?.pet_friendly : null}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="ms-1">Furnished?</h6>
                    <ul className="d-flex p-0">
                      <li className="li">
                        {detail ? detail?.furnished : null}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="ms-1">Short Term?</h6>
                    <ul className="d-flex p-0">
                      <li className="li">
                        {" "}
                        {detail ? detail?.short_term : null}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="ms-1">Lease terms</h6>
                    <ul className="d-flex p-0">
                      <li className="li">
                        {" "}
                        {detail ? detail?.lease_term : null}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="ms-1">Parking Type</h6>
                    <ul className="d-flex p-0">
                      <li className="li">
                        {" "}
                        {detail ? detail?.parking_type : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card  mt-3 br-theme">
                <h4 className="text-theme text-center">Property Description</h4>
                <div className="text-center">
                  <div dangerouslySetInnerHTML={{__html: desc}} />
                  {/* {detail ? detail?.description : null} */}
                </div>
              </div>

              <div className="card  mt-3 p-3 br-theme">
                <h4 className="text-theme text-center">
                  Features And Amenities
                </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <h6 className="ms-1">Comercial Building</h6>
                    <ul className="d-flex p-0">
                      {detail?.commercial_building.map((commercial) => {
                        return (
                          <>
                            <li className="li fs-7">{commercial.name}</li>
                            
                          </>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="col-lg-12">
                    <h6 className="ms-1">Building Feature</h6>
                    <ul className="d-flex p-0">
                      {detail?.building_feature.map((building)=>{
                      return(
                        <>
                      <li className="li">{building.name}</li>
                      </>
                      )
                    })}
                    </ul>
                  </div>

                  <div className="col-lg-12">
                    <h6 className="ms-1">Unit Features</h6>
                    <ul className="d-flex p-0">
                      {detail?.unit_feature.map((u)=>{
                        return(
                          <>
                      <li className="li">{u.name}</li>
                      </>
                      )
                    })}
                    </ul>
                  </div>

                  <div className="col-lg-12">
                    <h6 className="ms-1">Nearby</h6>
                    <ul className="d-flex p-0">
                      {detail?.near_by.map((near)=>{
                        return(
                          <>
                    <li className="li">{near.name}</li>
                    </>
                        )
                    })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card  mt-3 br-theme">
                <h4 className="text-theme text-center">Open House Dates</h4>
                <div className="row mt-3">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-3">
                    <h6>Date</h6>
                    {detail?.open_house_date.map((date)=>{
                      return(
                        <>
                  <p>{date.date}</p>
                  </>
                      )
                  })}
                  </div>
                  <div className="col-lg-3">
                    <h6>Start Time</h6>
                    {detail?.open_house_date.map((S_time)=>{
                      return(
                        <>
                    <p>{S_time.start_time}</p>
                    </>
                    )
                })}
                  </div>
                  <div className="col-lg-3">
                    <h6>End Time</h6>
                    {detail?.open_house_date.map((e_time)=>{
                      return(
                        <>
                    <p>{e_time.end_time}</p>
                    </>
                    )
                })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
        {/* Modal Start */}
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conatct landlord {detail?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row">
            <Form.Group className="col-lg-6 mb-3 d-none">
              <Form.Label>Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Name"
                value={cid}
                onChange={(e) => setCid(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Name"
                onChange={(e) => setName(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={detail ? detail?.address : "No address Found"}
                placeholder="Enter Address"
                onChange={(e) => setAddres(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Phone Number </Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter Your Phone Number"
                onChange={(e) => setNumber(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Price</Form.Label>
              {detail?.floor?.map((f) => (
                <Form.Control
                  type="text"
                  // placeholder="Price"
                  value={f.rent}
                  onChange={(e) => setPrice(e.target.value)}
                  autoFocus
                />
              ))}
            </Form.Group>

            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Property</Form.Label>
              <Form.Control
                type="text"
                placeholder="Property Name"
                onChange={(e) => setProprty(e.target.value)}
                value={detail ? detail?.property_type : "not found"}
                autoFocus
              />
            </Form.Group>
            <Form.Group className=" col-lg-12 mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={contactapi}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal end */}
    </>
  );
};
export default Propertydetails;
