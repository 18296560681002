import { useParams } from "react-router-dom";
import {
  faL,
  faLocation,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { FaPlus } from "react-icons/fa";
import Dropzone from "../../pages/Landlord/Dropzone";
import "../../pages/Landlord/editor.css";
import { Editor } from "react-draft-wysiwyg";
import { Handshake } from "@mui/icons-material";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import { Button } from "react-bootstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Logo from "../../asset/images/logo1.png";
import { set } from "immutable";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Dropdown from "react-bootstrap/Dropdown";

const Edit_property = () => {
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(true);

  const [File, setFile] = useState();
  console.log(File, "====>file");
  // const seleted_option = editdata?.category.map((category) => {
  //   {
  //     category?.name;
  //   }
  // });

  const options = [
    { value: "Cable", label: "Cable" },
    { value: "Heating", label: "Heating" },
    { value: "Hydro/Electricity", label: "Hydro/Electricity" },
    { value: "Wifi/Internet", label: "Wifi/Internet" },
    { value: "Satelite TV", label: "Satelite TV" },
    { value: "Water", label: "Water" },
  ];
  const cate = [
    { value: "Coprate Housing", label: "Coprate Housing" },
    { value: "Student Housing", label: "Student Housing" },
    { value: "Senior Housing", label: "Senior Housing" },
    { value: "Co-op Housing", label: "Co-op Housing" },
    { value: "Sublet", label: "Sublet" },
    { value: "Vacation", label: "Vacation" },
  ];
  const Comercial = [
    { value: "Commercial Access Gate", label: "Commercial Access Gate" },
    { value: "Commercial Heating", label: "Commercial Heating" },
    { value: "Commercial Securtiy Guard", label: "Commercial Securtiy Guard" },
    {
      value: "Commercial Air Conditoning",
      label: "Commercial Air Conditoning",
    },
    { value: "Commercial Locking Dock", label: "Commercial Locking Dock" },
  ];
  const buildfeature = [
    { value: "24h Security", label: "24h Security" },
    { value: "Bbq Area/Courtyard", label: "Bbq Area/Courtyard" },
    { value: "Bike Racks", label: "Bike Racks" },
    { value: "Bike Room", label: "Bike Room" },
    { value: "Buzzer Entry", label: "Buzzer Entry" },
    { value: "Central Air Conditioning", label: "Central Air Conditioning" },
    { value: "Electric Vehicle Charger", label: "Electric Vehicle Charger" },
    { value: "Elevator", label: "Elevator" },
    { value: "Exercise Room", label: "Exercise Room" },
    { value: "Fitness Area", label: "Fitness Area" },
    { value: "Garage", label: "Garage" },
    { value: "Guest Suite ", label: "Guest Suite " },
    { value: "Intercom", label: "Intercom" },
    { value: "Jacuzzi", label: "Jacuzzi" },
    { value: "Laundry Facilities", label: "Laundry Facilities" },
    { value: "Movie Room", label: "Movie Room" },
    { value: "On-Site Management", label: "On-Site Management" },
    { value: "On-Site Staff", label: "On-Site Staff " },
    { value: "Parking - Underground", label: "Parking - Underground" },
    { value: "Parking - Visitor", label: "Parking - Visitor" },
    { value: "Party Room", label: "Party Room" },
    { value: "Pet Friendly", label: "Pet Friendly" },
    { value: "Pool - Heated", label: "Pool - Heated" },
    { value: "Pool - Rooftop", label: "Pool - Rooftop" },
    { value: "Professionally Managed", label: "Professionally Managed" },
    { value: "Recreation Room", label: "Recreation Room" },
    { value: "Recycling", label: "Recycling" },
    { value: "Resident Managers", label: "Resident Managers" },
    { value: "Rooftop Deck", label: "Rooftop Deck" },
    { value: " Rooftop Garden", label: "Rooftop Garden" },
    { value: "Rooftop Lounge", label: "Rooftop Lounge" },
    { value: "Sauna", label: "Sauna" },
    { value: "Secured Access", label: "Secured Access" },
    { value: "Security On-Site", label: "Security On-Site" },
    { value: "Storage Lockers", label: "Storage Lockers" },
    { value: "Swimming Pool", label: "Swimming Pool" },
    { value: "Tennis Court", label: "Tennis Court" },
    { value: "Theatre Room", label: "Theatre Room" },
    { value: " Video Surveillance", label: " Video Surveillance" },
  ];
  const unifeature = [
    { value: "Air Conditioning", label: " Air Conditioning" },
    { value: " Alarm System", label: " Alarm System" },
    {
      value: " Backyard",
      label: " Backyard",
    },
    {
      value: "Balcony",
      label: " Balcony",
    },
    {
      value: "Bbq Grill",
      label: " Bbq Grill",
    },
    {
      value: "Blinds",
      label: " Blinds",
    },
    {
      value: "Cable Ready",
      label: " Cable Ready",
    },
    {
      value: "Dishwasher",
      label: " Dishwasher",
    },
    {
      value: "Dryer",
      label: " Dryer",
    },
    {
      value: "Ensuite Laundry",
      label: " Ensuite Laundry",
    },
    {
      value: "Fireplace ",
      label: " Fireplace ",
    },
    {
      value: "Flooring - Carpeted ",
      label: " Flooring - Carpeted ",
    },
    {
      value: "Flooring - Ceramic",
      label: " Flooring - Ceramic",
    },
    {
      value: "Flooring - Hardwood",
      label: " Flooring - Hardwood",
    },
    {
      value: "Flooring - Laminate",
      label: " Flooring - Laminate",
    },
    {
      value: "Flooring - Laminate Hardwood",
      label: " Flooring - Laminate Hardwood",
    },
    {
      value: "Flooring - Tile",
      label: " Flooring - Tile",
    },
    {
      value: "Flooring - Vinyl",
      label: " Flooring - Vinyl",
    },
    {
      value: "Flooring - Wood",
      label: " Flooring - Wood",
    },
    {
      value: "Freshly Painted",
      label: " Freshly Painted",
    },
    {
      value: "Furnished",
      label: " Furnished",
    },
    {
      value: "Garburator",
      label: " Garburator",
    },
    {
      value: "Garden",
      label: " Garden",
    },
    {
      value: "Gas Heating",
      label: " Gas Heating",
    },
    {
      value: "Granite Countertops",
      label: " Granite Countertops",
    },
    {
      value: "Hot Tub",
      label: " Hot Tub",
    },
    {
      value: "Individual Thermostats",
      label: " Individual Thermostats",
    },
    {
      value: "Island",
      label: " Island",
    },
    {
      value: "Microwave",
      label: " Microwave",
    },
    {
      value: "New Appliances",
      label: " New Appliances",
    },
    {
      value: "Newly Renovated",
      label: " Newly Renovated",
    },
    {
      value: "Parking",
      label: " Parking",
    },
    {
      value: "Patio",
      label: " Patio",
    },
    {
      value: "Pool - Private",
      label: " Pool - Private",
    },
    {
      value: "Private Entry",
      label: " Private Entry",
    },
    {
      value: "Private Yard",
      label: " Private Yard",
    },
    {
      value: "Radiant Heat",
      label: " Radiant Heat",
    },
    {
      value: "Security Cameras",
      label: " Security Cameras",
    },
    {
      value: "Shared Yard",
      label: " Shared Yard",
    },
    {
      value: "Storage",
      label: " Storage",
    },
    {
      value: "Terrace",
      label: " Terrace",
    },
    {
      value: "Walk-In Closet",
      label: " Walk-In Closet",
    },
    {
      value: "Washer",
      label: " Washer",
    },
    {
      value: "Wheelchair Access",
      label: " Wheelchair Access",
    },
    {
      value: "Window Coverings",
      label: " Window Coverings",
    },
  ];
  const near = [
    { value: " 24h Emergency", label: " 24h Emergency" },
    { value: " Atm", label: " Atm" },
    { value: " Bank", label: " Bank" },
    { value: " Bars", label: " Bars" },
    { value: " Bike Trails", label: " Bike Trails" },
    { value: " Bus Stop", label: " Bus Stop" },
    { value: " Cafe", label: " Cafe" },
    { value: " Convenience Store", label: " Convenience Store" },
    { value: " Daycare", label: " Daycare" },
    { value: " Dog Park", label: " Dog Park" },
    { value: " Gas Station", label: " Gas Station" },
    { value: " Grocery Store", label: " Grocery Store" },
    { value: " Gym", label: " Gym" },
    { value: " Highway", label: " Highway" },
    { value: " Hospital", label: " Hospital" },
    { value: " Movie Theater", label: " Movie Theater" },
    { value: " Parks", label: " Parks" },
    { value: " Playground", label: " Playground" },
    { value: " Pool", label: " Pool" },
    { value: " Public Library", label: " Public Library" },
    { value: " Public Transit", label: " Public Transit" },
    { value: " Recreation", label: " Recreation" },
    { value: " Restaurants", label: " Restaurants" },
    { value: " Running Path", label: " Running Path" },
    { value: " School", label: " School" },
    { value: " Schools", label: " Schools" },
    { value: " Shopping", label: " Shopping" },
    { value: " Shopping Centre", label: " Shopping Centre" },
  ];

  const dropzoneStyle = {
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
  };

  const [inputList, setinputList] = useState([
    {
      id: uuid(),
      bed: "",
      bath: "",
      price: "",
      availability: "",
      unit_size: "",
    },
  ]);
  const handleaddclick = () => {
    setinputList([
      ...inputList,
      {
        id: uuid(),
        bed: "",
        bath: "",
        price: "",
        availability: "",
        unit_size: "",
      },
    ]);
  };

  const removeclick = (index) => {
    const list = [...inputList];
    console.log(index);
    list.splice(index, 1);
    setinputList([...list]);
  };
  const [houseList, sethouseList] = useState([
    { id: uuid(), date: "", start_time: "", end_time: "" },
  ]);
  console.log(houseList, "houselistcheck");
  const addhouse = () => {
    sethouseList([
      ...houseList,
      { id: uuid(), date: "", start_time: "", end_time: "" },
    ]);
  };

  const removehouse = (index) => {
    const home = [...houseList];
    console.log(index);
    home.splice(index, 1);
    sethouseList([...home]);
  };
  const [apartment, setApartment] = useState(false);
  const [house, setHouse] = useState(false);
  const [room, setRoom] = useState(false);
  const [html, setHtml] = useState("");
  const [apartm, setApartm] = useState("");
  const [Editors, setEditors] = useState();

  function apart(e) {
    setApartm(e.target.value);
    console.log(apartm, ",,,,,,,,,,,,,,,,,,,,,");
    console.log(typeof e.target.value);
    if (e.target.value === "1") {
      setApartment(true);
      setHouse(false);
      setRoom(false);
    } else if (e.target.value === "2") {
      setHouse(true);
      setApartment(false);
      setRoom(false);
    } else if (e.target.value === "3") {
      setRoom(true);
      setHouse(false);
      setApartment(false);
    } else if (e.target.value === "0") {
      setRoom(false);
      setHouse(false);
      setApartment(false);
    } else {
      // alert("Check your Details!");
    }
  }
  const currentYear = 2022;
  const years = [];
  for (let index = 1800; index <= currentYear; index++) {
    years.push(index);
    // console.log(years);
  }

  const handleChangeInput = (floorid, floorevent) => {
    // console.log("a", floorid);
    // console.log("b", floorevent);

    const newInputField = inputList.map((i) => {
      if (floorid === i.id) {
        // console.log("daaaaaaaadaaaaaa123234213", i);
        i[floorevent.target.name] = floorevent.target.value;
        // console.log("id", floorid);
      }
      return i;
    });
    setinputList(newInputField);
  };
  const handleChangehouselist = (floorid, floorevent) => {
    console.log("a", floorid);
    console.log("b", floorevent);

    const newInputField = houseList.map((i) => {
      if (floorid === i.id) {
        // console.log("daaaaaaaadaaaaaa123234213", i);
        i[floorevent.target.name] = floorevent.target.value;
        // console.log("id", floorid);
      }
      return i;
    });
    sethouseList(newInputField);
  };

  const onEditorStateChange = (editorState) => {
    setEditors(editorState);
    let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtml(data);
    console.log(html, "trext arera");
  };
  // Get data From Api
  const [editdata, setEditdata] = useState();
  const { id } = useParams();
  console.log(id, "====> id");

  const userid = localStorage.getItem("user");
  const token = localStorage.getItem("accesstoken");

  const editapi = () => {
    var config = {
      method: "get",
      url: `http://gtaadmin.sasstechnologies.com/api/landlord/edit/property/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("BEDR0OMS", response.data.property.floor[0].bedroom);
        setEditdata(response.data.property);
        console.log(response.data, "editproperty");
        const blocksFromHtml = htmlToDraft(response.data.property.description);

        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditors(EditorState.createWithContent(contentState));
        setCategories(
          response.data?.property?.category.map((category, i) => ({
            label: category.name,
            value: category.name,
          }))
        );
        setUtilies(
          response.data?.property?.utility_include.map(
            (utility_include, i) => ({
              label: utility_include.name,
              value: utility_include.name,
            })
          )
        );
        setBuilding(
          response.data?.property?.commercial_building.map(
            (commercial_building, i) => ({
              label: commercial_building.name,
              value: commercial_building.name,
            })
          )
        );
        setFeature(
          response.data?.property?.building_feature.map(
            (building_feature, i) => ({
              label: building_feature.name,
              value: building_feature.name,
            })
          )
        );
        setUnitFeature(
          response.data?.property?.unit_feature.map((unit_feature, i) => ({
            label: unit_feature.name,
            value: unit_feature.name,
          }))
        );
        setNearby(
          response.data?.property?.near_by.map((near_by, i) => ({
            label: near_by.name,
            value: near_by.name,
          }))
        );
        setAddress(response.data.property.address)
        setUnit(response.data.property.unit)
        setLat(response.data.property.lat)
        setLng(response.data.property.lng)
        setPropertyId(response.data.property.id)
        setApartm(response.data.property.property_type)
        setAparttype(response.data.property.sub_property_type)
        setYear(response.data.property.year_build)
        setPet(response.data.property.pet_friendly)
        setFurnished(response.data.property.furnished)
        setTerms(response.data.property.short_term)
        setLease(response.data.property.lease_term)
        setParking(response.data.property.parking_type)
        setinputList(response.data.property.floor.map((e)=>({
          id: e.id,
          bed: e.bedroom,
          bath: e.bathroom,
          price:e.rent,
          availability:e.availability,
          unit_size: e.unit_size,
        })))
        if(response.data.property.open_house_date.length !== 0){

          sethouseList(response.data.property.open_house_date.map((e)=>({
            id: e.id,
            date: e.date,
            start_time: e.start_time,
           end_time:e.end_time,
           
          })))
        }
        // setEditors(response.data.property.description)
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("api", editdata);
  
  useEffect(() => {
    editapi();
  }, []);
  // Update Api
  const [aparttype, setAparttype] = useState("");
  const [housetype, setHousetype] = useState("");
  const [roomtype, setRoomtype] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [Utilies, setUtilies] = useState();
  const [Categories, setCategories] = useState("");
  const [year, setYear] = useState("");
  const [pet, setPet] = useState("");
  const [furnished, setFurnished] = useState("");
  const [terms, setTerms] = useState("");
  const [lease, setLease] = useState("");
  const [parking, setParking] = useState("");
  const [building, setBuilding] = useState("");
  const [feature, setFeature] = useState("");
  const [UnitFeature, setUnitFeature] = useState("");
  const [Nearby, setNearby] = useState("");
  console.log(Editors, "editor value checking......");
  // console.log(dropimg, "drop checking......");
  const [propertyId, setPropertyId] = useState("");
  // const [dropimg, setDropimg] = useState("");
  const property = localStorage.getItem("property");
  const [api, setApi] = useState();
  const tokens = localStorage.getItem("accesstoken");
  const Navigate = useNavigate();

  const add = () => {
    console.log(html)
    if(File !== null && File.length !== 0){
      imageapi();
    }
 
    try {
      const property = new FormData();
      property.append("property_id", propertyId);
      property.append("property_type", apartm);
      property.append("sub_property_type", aparttype);
      property.append("address", address);
      property.append("lat", lat);
      property.append("lng", lng);
      property.append("unit", unit);
      property.append("year_build", year);
      property.append("pet_friendly", pet);
      property.append("furnished", furnished);
      property.append("short_term", terms);
      property.append("lease_term", lease);
      property.append("parking_type", parking);
      property.append("description", html);
      console.log(Editors, "description");
      Utilies.map((e) => {
        console.log(e, "checking........");
        property.append("untility_name[]", e.value);
      });
      Categories.map((e) => {
        property.append("category_name[]", e.value);
      });
      inputList.map((e, i) => {
        console.log("===========> avialability", e, i);
        property.append(`floor_id[${i}]`, e.id);
        property.append(`bedroom[${i}]`, e.bed);
        property.append(`bathroom[${i}]`, e.bath);
        property.append(`rent[${i}]`, e.price);
        property.append(`availability[${i}]`, e.availability);
        property.append(`unit_size[${i}]`, e.unit_size);
      });
      building.map((e) => {
        property.append(`commercial_building_name[]`, e.value);
      });
      feature.map((e) => {
        property.append(`building_feature_name[]`, e.value);
      });
      UnitFeature.map((e) => {
        property.append(`unit_feature_name[]`, e.value);
      });
      Nearby.map((e) => {
        property.append(`near_by_name[]`, e.value);
      });
      houseList.map((e, i) => {
        property.append(`open_house_id[${i}]`, e.id);
        property.append(`date[${i}]`, e.date);
        property.append(`start_time[${i}]`, e.start_time);
        property.append(`end_time[${i}]`, e.end_time);
      });
      var config = {
        method: "POST",
        url: `https://gta.developer-hi.xyz/api/landlord/edit/propertyprocess`,
        data: property,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${tokens}`,
        },
      };

      axios(config).then(function (response) {
        setApi(response.data.property);
        // const { message, sstatus, token } = response.data;
        console.log(response.data.property, "profile");
        console.log(response, "api");
        console.log(response.data.message, "api");
        console.log(response.data.status, "api");
        console.log(response.data.description, "resp");
        // console.log(sstatus, "api");
        if (response.data.status === true) {
          swal({
            title: "Good job!",
            text: response.data.message,
            icon: "success",
            button: "Ok",
          });
          Navigate("/landlordproperty");
        } else {
        }
      });
    } catch (errors) {
      console.log(errors, errors.errors, errors.errors, "chup beth madarchod");
      swal({
        title: "Something Went Wrong",
        text: errors.errors,
        icon: "error",
        dangerMode: true,
      });
    }
  };




  // SIde Bar Apis
  const [data, setData] = useState(null);
  // const token = localStorage.getItem("accesstoken");
  const profile = () => {
    var config = {
      method: "get",
      url: "http://gtaadmin.sasstechnologies.com/api/landlord/profile/view",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setData(response.data);
        console.log(response.data, "wworking");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    profile();
  }, []);

  const imageapi = () => {
    const image = new FormData();
    image.append("property_id", propertyId);
    File.map((e) => {
      image.append("file", e);
    });
    var config = {
      method: "POST",
      url: `http://gtaadmin.sasstechnologies.com/api/landlord/upload/propertyImage`,
      data: image,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${tokens}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPropertyId(response.data.property);
        console.log(response.data, "image");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const Navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/landlordlogin");
  };
  // Side bar apis end

  useEffect(() => {
    console.log(token);
    if (token) {
      console.log("logged in");
    } else {
      Navigate("/landlordlogin");
    }
  }, []);
  return (
    <>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo ">
            <img
              src={data === null ? data?.landlord?.image : Logo}
              alt=""
              height="100%"
              width="100%"
              className="img-thumbnail"
            />
          </div>
          <div>
            <div className="text-center mt-5 mb-3 fs-6 ">
              <h6 className="text-white">{data?.landlord?.first_name}</h6>
            </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["3"]}
            items={[
              {
                key: "1",
                icon: <UserOutlined />,
                label: <Link to="/landlorddashboard">DashBoard</Link>,
              },
              {
                key: "2",
                icon: <VideoCameraOutlined />,
                label: <Link to="/landlordproperty">Property</Link>,
              },
              {
                key: "3",
                icon: <UploadOutlined />,
                label: <Link to="/landlordaddproperty">Add Property</Link>,
              },
              // {
              //   key: '4',
              //   icon: <UploadOutlined />,
              //   label: 'Log Out',
              //   onClick: { logout }
              // },
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
            }}
          >
            <div className="row">
              <div className="col-md-6">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {data?.landlord?.email}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <a
                        className="btn btn-success btn-block xsmall"
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="text-right fs-6 mt-4 ms-2 ">
                <h6 className="text-dark">{datas?.landlord?.email}</h6>
              </div> */}
              </div>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              height: "auto",
            }}
          >
            <section className="mb-5">
              <div className="container">
                <div className="row">
                  <div className="offset-md-2 col-md-9 ">
                    <div className="card shadow">
                      <div className="card-header d-flex justify-content-center">
                        <h2 className="text-black text-center">
                          Edit PROPERTY
                        </h2>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="form-group col-md-6 mb-3">
                              <label for="address">Property ID</label>
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                tabindex="2"
                                value={id}
                                required
                                disabled
                                
                              />
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label for="address">User ID</label>
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                tabindex="2"
                                required
                                value={userid}
                                disabled
                              />
                            </div>

                            <div className="form-group col-md-12 mb-3">
                              <label for="Property Type">Property Type</label>
                              <select
                                // value={editdata?.property_type}
                                className="form-select"
                                aria-label="Default select example"
                                disabled
                                onChange={apart}
                              >
                                <option>{editdata?.property_type}</option>
                                <option value="Condo">Condo</option>
                                <option value="Apartment">Apartment</option>
                                <option value="House">House</option>
                                <option value="Room">Room</option>
                              </select>
                            </div>
                            <div className="form-group col-md-12 mb-3">
                              <label for="Property Type">Sub Property</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                disabled
                                // onChange={(e) => setAparttype(e.target.value)}
                              >
                                <option value={editdata?.sub_property_type}>
                                  {editdata?.sub_property_type}
                                </option>
                              </select>
                            </div>

                            <div className="form-group col-md-9 mb-5">
                              <label for="address">Address</label>
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                tabindex="2"
                                onChange={(e) => setAddress(e.target.value)}
                                required
                                value={address}
                              />
                            </div>

                            <div className="form-group col-md-3 mb-5">
                              <label for="unit">Unit</label>
                              <input
                                value={unit}
                                className="form-control"
                                type="number"
                                name="unit"
                                tabindex="2"
                                onChange={(e) => setUnit(e.target.value)}
                                required
                              />
                            </div>

                            <div className="form-group col-md-6 mb-5">
                              <label for="lat">Lattitude</label>
                              <input
                                value={lat}
                                className="form-control"
                                type="number"
                                name="lat"
                                tabindex="2"
                                onChange={(e) => setLat(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group col-md-6 mb-5">
                              <label for="lng">longitude</label>
                              <input
                                value={lng}
                                className="form-control"
                                type="number"
                                name="lng"
                                tabindex="2"
                                onChange={(e) => setLng(e.target.value)}
                                required
                              />
                            </div>
                            <h4 className="text-success text-center">
                              Property Details
                            </h4>

                            <div className="form-group col-md-6 mb-3">
                              <label for="utilies">Utilies Included</label>
                              <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={options}
                                value={Utilies}
                                onChange={(e) => {
                                  console.log(e);
                                  setUtilies(e);
                                }}
                              />
                            </div>

                            <div className="form-group col-md-6 mb-3">
                              <label for="categories"> Categories</label>
                              <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={cate}
                                value={Categories}
                                onChange={(e) => {
                                  console.log(e);
                                  setCategories(e);
                                }}
                              />

                              {/* {editdata?.category.map((category,i) =>({label:category.name,value:category.name}))} */}
                            </div>

                            <div className="form-group col-md-6 mb-3">
                              <label for="Year"> Year</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setYear(e.target.value)}
                              >
                                <option disabled selected className="fs-4">
                                  {editdata?.year_build}
                                </option>
                                {years.length != 0 ? (
                                  years.map((chay) => (
                                    <option value={chay}>{chay}</option>
                                  ))
                                ) : (
                                  <option>loading....</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label for="Pet Firendly"> Pet Firendly</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setPet(e.target.value)}
                              >
                                <option disabled selected className="fs-4">
                                  {editdata?.pet_friendly}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <div className="form-group col-md-6 mb-3">
                              <label for="Furnished?"> Furnished?</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setFurnished(e.target.value)}
                              >
                                <option disabled selected className="fs-4">
                                  {editdata?.furnished}
                                </option>
                                <option value="yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label for="Short Term?"> Short Term?</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setTerms(e.target.value)}
                              >
                                <option disabled selected className="fs-4">
                                  {editdata?.short_term}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label for="Lease terms"> Lease terms</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setLease(e.target.value)}
                              >
                                <option disabled selected className="fs-4">
                                  {editdata?.lease_term}
                                </option>
                                <option value="Years">Years</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Negotiable">Negotiable</option>
                              </select>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label for="Parking Type"> Parking Type</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setParking(e.target.value)}
                              >
                                <option disabled selected className="fs-4">
                                  {editdata?.parking_type}
                                </option>
                                <option value="NoParking">No Parking</option>
                                <option value="Garage">Garage</option>
                                <option value="Driveway">Driveway</option>
                                <option value="Underg">Underg</option>
                                <option value="Street">Street</option>
                              </select>
                            </div>

                            <h4 className="text-black text-center">
                              Floor Plans
                            </h4>
                            {/* cloing */}
                            <div className="productdiv mb-3">
                              {inputList.map((x, i) => {
                                return (
                                  <div className="row">
                                    <div className="form-group col-md-2">
                                      <label for="bathrrom">Bedrooms</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="bed"
                                        tabindex="2"
                                        required
                                        value={x.bed}
                                        onChange={(e) =>
                                          handleChangeInput(x.id, e)
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-2">
                                      <label for="bath">Bath</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="bath"
                                        tabindex="2"
                                        required
                                        value={x.bath}
                                        onChange={(e) =>
                                          handleChangeInput(x.id, e)
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label for="price">Price</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="price"
                                        tabindex="2"
                                        required
                                        value={x.price}
                                        onChange={(e) =>
                                          handleChangeInput(x.id, e)
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label for="date">Avalaible Date</label>
                                      <input
                                        type="date"
                                        name="availability"
                                        className="form-control"
                                        value={x.availability}
                                        onChange={(e) =>
                                          handleChangeInput(x.id, e)
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-2">
                                      <label for="unit">Unit size</label>
                                      <input
                                        type="tel"
                                        name="unit_size"
                                        className="form-control"
                                        value={x.unit_size}
                                        onChange={(e) =>
                                          handleChangeInput(x.id, e)
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-2">
                                      <div className="row mt-32 ">
                                        <div className="col-md-6 ">
                                          {inputList.length - 1 === i && (
                                            <button
                                              type="button"
                                              className="btn  w-100 border text-success mt-4 "
                                              title="Add"
                                              onClick={handleaddclick}
                                            >
                                              <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                          )}
                                        </div>
                                        {inputList.length !== 1 && (
                                          <div className="col-md-6 text-center ">
                                            <button
                                              type="button"
                                              title="remove"
                                              className="btn  text-danger border w-100 removebtn mt-4"
                                              onClick={removeclick}
                                            >
                                              <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {/* cloing end */}

                            <div id="moreproduct"></div>

                            <h4 className="text-success my-3  text-center">
                              Photos
                            </h4>
                            <div className="form-group col-md-12">
                              img
                              <label className="ms-5">Multiple files</label>
                              <Dropzone setFile={setFile} />
                            </div>
                            <h4 className="text-success my-3  text-center">
                              Description
                            </h4>
                            <div className="form-group col-md-12 mb-5">
                              <Editor
                                editorState={Editors}
                                onEditorStateChange={onEditorStateChange}
                                // onChange={(e) => setEditors(e)}
                                // onFocus={(event) => {}}
                              />
                              {/* <textarea
                                disabled
                                value={draftToHtml(
                                  convertToRaw(editorState.getCurrentContent())
                                )}
                              /> */}
                            </div>

                            <h4 className="text-success text-center mb-3">
                              Features And Amenities
                            </h4>

                            <div className="form-group col-md-6 mb-3">
                              <label for="utilies"> Comercial Building</label>
                              <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={Comercial}
                                value={building}
                                onChange={(e) => {
                                  console.log(e);
                                  setBuilding(e);
                                }}
                              />
                            </div>

                            <div className="form-group col-md-6 mb-3">
                              <label for="categories"> Building Feature</label>
                              <Select
                                closeMenuOnSelect
                                isMulti
                                options={buildfeature}
                                value={feature}
                                onChange={(e) => {
                                  console.log(e);
                                  setFeature(e);
                                }}
                              />
                            </div>
                            <div className="form-group col-md-6 mb-3">
                              <label for="Year"> Unit Features</label>
                              <Select
                                closeMenuOnSelect
                                isMulti
                                options={unifeature}
                                value={UnitFeature}
                                onChange={(e) => {
                                  console.log(e);
                                  setUnitFeature(e);
                                }}
                              />
                            </div>

                            <div className="form-group col-md-6 mb-3">
                              <label for="Pet Firendly">Nearby</label>
                              <Select
                                closeMenuOnSelect
                                isMulti
                                options={near}
                                value={Nearby}
                                onChange={(e) => {
                                  console.log(e);
                                  setNearby(e);
                                }}
                              />
                            </div>

                            <h4 className="text-success text-center">
                              Open House Dates
                            </h4>
                            {/* {{-- Date and time --}} */}
                            <div className="producttime mt-2">
                              {houseList.map((x, i) => {
                                return (
                                  <div className="row mt-4">
                                    <div className="form-group col-md-3">
                                      <label for="">
                                        <i className="fas fa-calendar"></i> Date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control "
                                        name="date"
                                        value={
                                          x.date
                                        }
                                        onChange={(e) =>
                                          handleChangehouselist(x.id, e)
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label for="">
                                        <i className="fas fa-clock"></i> Start
                                        Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control"
                                        name="start_time"
                                        value={
                                         x.start_time
                                        }
                                        onChange={(e) =>
                                          handleChangehouselist(x.id, e)
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label for="">
                                        <i className="fas fa-clock"></i> End
                                        Time
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control"
                                        name="end_time"
                                        value={
                                          x.end_time
                                        }
                                        onChange={(e) =>
                                          handleChangehouselist(x.id, e)
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-2">
                                      <div className="row mt-32 ">
                                        <div className="col-md-6 ">
                                          {houseList.length - 1 === i && (
                                            <button
                                              type="button"
                                              className="btn  w-100 border text-success mt-4 "
                                              title="Add"
                                              onClick={addhouse}
                                            >
                                              <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                          )}
                                        </div>
                                        <div className="col-md-6 text-center ">
                                          {houseList.length !== 1 && (
                                            <button
                                              type="button"
                                              title="remove"
                                              className="btn  text-danger border w-100 removetime mt-4"
                                              onClick={removehouse}
                                            >
                                              <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="col-md-5"></div>
                            <div className="col-md-6 mt-5">
                              <Button
                                className="btn btn-success btn-block"
                                onClick={add}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default Edit_property;
