import React, { useEffect, useState } from "react";
import Authheader from "../../component/Authheader";
import Authfooter from "../../component/Authfooter";
import SideBar from "../../component/SideBar";
import { Card } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { CardContent } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { Content } from "antd/lib/layout/layout";
import Dropdown from 'react-bootstrap/Dropdown';
import Property from './Property'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import './editor.css'
import Logo from "../../asset/images/logo1.png";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utlilites/axios";

export const Landlorddashboard = () => {
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [data, setData] = useState(null);
  const token = localStorage.getItem("accesstoken");
  const profile = () => {
    var config = {
      method: "get",
      url: "http://gtaadmin.sasstechnologies.com/api/landlord/profile/view",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setData(response.data);
        console.log(response.data, "wworking");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    profile();
  }, []);
  const Navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/landlordlogin");
  };

  useEffect(()=>{
    console.log(token)
if(token){
  console.log("logged in")
}else{
  Navigate('/landlordlogin')
}
  },[]) 
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo ">
          <img
            src={data === null ? data?.landlord?.image : Logo}
            alt=""
            height="100%"
            width="100%"
            className="img-thumbnail "
          />
        </div>
        <div>

          <div className="text-center mt-5 mb-3 fs-6">
            <h6 className="text-white">{data?.landlord?.first_name}</h6>
          </div>

        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '1',
              icon: <UserOutlined />,
              label:
                <Link to="/landlorddashboard">
                  DashBoard
                </Link>

            },
            {
              key: '2',
              icon: <VideoCameraOutlined />,
              label: <Link to="/landlordproperty">
                Property
              </Link>,

            },
            {
              key: '3',
              icon: <UploadOutlined />,
              label: <Link to="/landlordaddproperty">
                Add Property
              </Link>
            },
            // {
            //   key: '4',
            //   icon: <UploadOutlined />,
            //   label: 'Log Out',
            //   onClick: { logout }
            // },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
          <div className="row">
            <div className="col-md-6">
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              })}
            </div>
            <div className="col-md-2"></div>
              <div className="col-md-4">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {data?.landlord?.email}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >
                    <a
                        className="btn btn-success btn-block xsmall"
                        onClick={logout}
                      >
                        Logout
                      </a>
                      </Dropdown.Item>
                 
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="text-right fs-6 mt-4 ms-2 ">
                <h6 className="text-dark">{datas?.landlord?.email}</h6>
              </div> */}
              </div>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            height: "34rem"
          }}
        >
          <div className="container ">
            <div className="row">
              <div className="  col-md-12 ">
                <div
                  className=""
                // style={{ marginLeft: "200px", width: "100% ", height: "50vh" }}
                >
                  <div className=" my-2">
                    <h3 className="color1 ms-2">Landlord DashBoard</h3>
                    <hr />
                  </div>

                  <div className="">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4">
                          <Card className="my-5 he br-1">
                            <CardContent>
                              <FontAwesomeIcon
                                icon={faBuilding}
                                className="mb-2"
                                style={{ color: "#fff", zoom: 3, width: "16px" }}
                              />
                              <br />
                              <h3 className="text-dark">456</h3>
                              <h4 className="text-muted">Total Property</h4>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout >
  );

};

export default Landlorddashboard;
