/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
      <footer className="footer bg-theme space-pt">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div className="footer-link">
                <h5 className="text-dark mb-4">Useful links</h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#">Rent Report </a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                  <li>
                    <a href="#">Terms</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-link">
                <div className="footer-contact-info">
                  <h5 className="text-dark mb-4">LANDLORDS</h5>

                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link
                        to="/landlordlogin"
                        className="btn btn-light text-dark"
                      >
                        Post For Free
                      </Link>
                    </li>
                    <li>
                      <a>My Listings </a>
                    </li>
                    <li>
                      <a>Email Preferences</a>
                    </li>
                    <li>
                      <a>Contact Support</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-link">
                <div className="footer-contact-info">
                  <h5 className="text-dark mb-4">POPULAR SEARCHES</h5>

                  <ul className="list-unstyled mb-0">
                    <li>
                      <a>Apartments & Condos Near Me</a>
                    </li>
                    <li>
                      <a>Apartments Near Me</a>
                    </li>
                    <li>
                      <a>Condos Near Me</a>
                    </li>
                    <li>
                      <a>Houses Near Me</a>
                    </li>
                    <li>
                      <a>Rooms Near Me</a>
                    </li>
                    <li>
                      <a>All Rentals Near Me</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 text-center text-md-start"></div>

              <div className="col-md-4 text-center text-md-end">
                <p className="mb-0 text-dark">
                  &copy;Copyright
                  <span id="copyright">
                    <script>
                      document.getElementById('copyright').appendChild(document.createTextNode(new
                      Date().getFullYear()))
                    </script>
                  </span>
                  <a href="#" className="text-dark">
                    {" "}
                    GTA Rental{" "}
                  </a>{" "}
                  All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
