/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  faBath,
  faBed,
  faBuilding,
  faCalendar,
  faLocation,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import Footer from "../component/Footer";
import Header from "../component/Header";
import Searchapp from "../component/Search";
import axios from "../utlilites/axios";

import "./Custom.css";
import { Link, useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = new useNavigate();
  const [loader, setLoader] = useState(true);
  const [getproperty, setGetproperty] = useState();
  const [path, setPath] = useState();

  const property = () => {
    setLoader(true);
    // const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: "http://gtaadmin.sasstechnologies.com/api/veiw/property",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        setGetproperty(response.data.property);
        setPath(response.data.imagepath);
        console.log(response, "checking");
        if (response.status) {
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("api", getproperty);

  useEffect(() => {
    property();
  }, []);

  return (
    <>
      <Header />

      {loader ? <div className="loader"></div> : null}

      <section className="banner bg-holder bg-overlay-black-30 banner-1">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative my-5rem">
              <h1 className="text-white text-center mb-2 font fw-bolder">
                Renting Made Easy
              </h1>
              <p className="lead text-center text-white mb-4 fw-normal font">
                Thousands of apartments, houses, and condos for rent across
                Canada
              </p>
              <div className="col-12">
                <div className="container h-100">
                  <div className="d-flex justify-content-center h-100">
                    <Searchapp />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-pb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center my-5">
                <h2>Popular Cities</h2>
                <p>Find your next apartment for rent anywhere in Canada</p>
              </div>
            </div>
          </div>
          {console.log(getproperty, "line 127")}
          <div className="row">
            {getproperty?.map((i) => {
              return (
                <div className="col-sm-6 col-md-3">
                  <div className="property-item">
                    <div onClick={() => navigate("/propertydetail" + i.id)}>
                      <div className="property-image bg-overlay-gradient-04">
                        {i.property_image.slice(0, 1).map((x) => (
                          <img
                            className=""
                            src={`${path}${x.image}`}
                            alt="NO image Found "
                            width={"100%"}
                            height={174.6}
                          />
                        ))}
                      </div>
                      <div className="property-details">
                        <div className="property-details-inner">
                          <h5 className="property-title">
                            <a href="property-detail-style-01.html">
                              {i.property_type}
                            </a>
                          </h5>
                          <span className="property-address">
                            <FontAwesomeIcon icon={faBuilding} />
                            {"\u00a0\u00a0"}
                            {i.sub_property_type
                              ? i.sub_property_type
                              : "No Sub Property Found"}
                          </span>
                          <br />

                          <span className="property-address">
                            <FontAwesomeIcon icon={faLocation} />
                            {"\u00a0\u00a0"}
                            {i.address}
                          </span>
                          <br />
                          {i.floor.map((x) => {
                            return (
                              <span className="property-agent-date">
                                <FontAwesomeIcon icon={faCalendar} />
                                {"\u00a0\u00a0"}
                                {x.availability}
                              </span>
                            );
                          })}
                          <div className="property-price">
                            $
                            {i.floor.map((x) => {
                              return <>{x.rent}</>;
                            })}
                            <span> / month</span>
                          </div>
                          <ul className="property-info list-unstyled d-flex">
                            <li className="flex-fill property-bed">
                              <FontAwesomeIcon icon={faBed} />
                              {i.floor.map((x) => {
                                return (
                                  <>
                                    Bed<span>{x.bedroom}</span>
                                  </>
                                );
                              })}
                            </li>
                            <li className="flex-fill property-bath">
                              <FontAwesomeIcon icon={faBath} />
                              {i.floor.map((x) => {
                                return (
                                  <>
                                    Bath<span>{x.bathroom}</span>
                                  </>
                                );
                              })}
                            </li>
                            <li className="flex-fill property-m-sqft">
                              <FontAwesomeIcon icon={faSquare} /> sqft
                              {i.floor.map((x) => {
                                return (
                                  <>
                                    <span>{x.unit_size}m</span>
                                  </>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="mt-0 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5"></div>
            <div className="col-lg-4 ">
              <Link
                to="/AllProperty"
                className="btn btn-outline-success btn-lg"
              >
                See More......
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
